 <template>
    <!-- Sticky Title -->
    <div class="position-sticky hastags cardBorder radius20 d-flex justify-content-between align-items-center bg-white boxed mb-3" v-if="selectedHastag">
        <span class="d-inline-flex pointer badge bg-light text-dark" @click="generalSocialList();selectedHastag=''">#{{selectedHastag}} <vue-feather type="x" size="12" class="ms-1"></vue-feather ></span>
    </div>
    <div class="shareScroll" id="tab2">
        <loginPopup v-if="showLoginPopup"></loginPopup>
        <div class="col-12 position-relative">
            <!-- Add Comment Top -->
            <div class="newsfeed-card position-sticky pt-4" v-if="store.user.access_token">
                <div class="d-flex justify-content-start position-relative">
                    <div class="icon me-2">
                        <a href="javascript:void(0)" class="avatar-frame ">
                            <v-lazy-image v-if="Object.keys(store.userDetail || {}).length && store.userDetail.imageUrl" class="rounded-circle d-block flex-none" :src="store.userDetail.imageUrl" :alt="store.userDetail.profileName || ''" height="36" width="36" />
                            <span v-else class="userIcon me-3">{{(store.userDetail.firstName) ? store.userDetail.firstName.charAt(0) : ''}}{{(store.userDetail.lastName) ? store.userDetail.lastName.charAt(0) : ''}}</span>
                            <!-- <v-lazy-image v-else class="rounded-circle d-block flex-none" src="/assets/images/profile-pic.png" :alt="store.userDetail.profileName || ''" height="36" width="36" /> -->
                        </a>
                    </div>
                    <div class="writepost">
                        <textarea :class="[{'focused':focused}]" class="f-14" v-model="topComment" @blur="focused=false" @focus="focused=true" :placeholder="`${$t('leader_profile.postPlaceholderstart')} @ ${$t('leader_profile.postPlaceholderEnd')}`" @input="hastagAT()"></textarea>
                        <p class="text-danger f-12 p-0 m-0">{{mediaError}}</p>
                    </div>
                </div>
                <div class="p-2 searchAutohasAt bg-white box-shadow boxed border position-absolute" v-if="store.socialTopCommentHastagName && store.socialTopCommentHastagName.length && topComment">
                    <ul class="autohasAt m-0">
                        <li v-for="item,key in store.socialTopCommentHastagName" @click="pasteTagTop(item.name)" :key="key" :id="item.name" class="pointer f-14">{{removeSpace(item.name)}}</li>
                    </ul>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center" v-if="commentImage.name">
                        <div class="uploadDocument_preview">
                            <video v-if="commentImage.type.includes('video')" controls class="w-100" :src="commentImageSRC" :title="$t('leader_profile.uploadedVideo')" :alt="$t('leader_profile.uploadedVideo')"></video>
                            <span v-else><v-lazy-image  class="w-100" :src="commentImageSRC" :title="$t('leader_profile.uploadedImage')" :alt="$t('leader_profile.uploadedImage')"/></span>
                        </div>
                        <a @click="commentImage={}" href="javascript:void(0)" class="trashNull"><vue-feather size="18" type="trash-2"></vue-feather></a>
                    </div>
                    <div v-else class="d-flex align-items-center feedWirteSsomething">
                        <div class="upload-btn-wrapper pointer me-2 me-md-3">
                            <button class="btn d-flex align-items-center"><v-lazy-image class="me-2" src="/assets/images/uploadIcon.png" :alt="$t('leader_profile.uploadImage')" /> {{$t('leader_profile.photo')}}</button>
                            <input type="file" name="myImagefile" id="myImagefile" accept="image/*" @change="uploadMedia($event)"/>
                        </div>
                        <div class="upload-btn-wrapper pointer">
                            <button class="btn d-flex align-items-center"><v-lazy-image class="me-2" src="/assets/images/uloadPlay.png" :alt="$t('leader_profile.uploadVideo')" /> {{$t('leader_profile.video')}}</button>
                            <input type="file" name="myVideofile" id="myVideofile" accept="video/mp4" @change="uploadMedia($event)"/>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center">
                        <a class="secondary pointer me-2 f-12" @click="topComment=''" v-if="topComment">Clear</a>
                        <button :class="[{'disabled':!topComment},{'disabled' : store.customerDetail?.readOnly}]" class="sendmessage align-items-center d-flex shadow-none" @click="commentTopPost()">
                            <!-- <v-lazy-image class="" src="/assets/images/sendmessage.svg" alt="" width="15" height="15" /> -->
                            <vue-feather class="rotateSend" size="18" type="send"></vue-feather>
                        </button>
                    </div>
                </div>
            </div>
            <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
            <div v-else class="SocialTypeComments">
                <!-- Social type comments TOP -->
                <div class="mb-3" v-if="(store.socialTypeList.content || []).length > 0 && !($route.name=='socialFeed' || $route.name=='myPosts') && Object.keys(store.socialTypeCommentList).length">
                    <div class="bg-white boxed radius12 card-body mb-2" v-for="social,key in store.socialTypeList.content.slice(0,1)" :key="key">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="d-flex align-items-center">
                                <span class="me-2">
                                    <span v-if="social.fromZuluAccountId">
                                        <v-lazy-image width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/webservices/Image.ashx?type=user&size=XL&id='+social.fromZuluAccountId+'&ignore=true'" :alt="store.traderDetail.trader.stats.profile.name || '-'" :title="store.traderDetail.trader.stats.profile.name || '-'" />
                                    </span>
                                    <span v-else>
                                        <v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" :alt="store.traderDetail.trader.stats.profile.name || '-'" :title="store.traderDetail.trader.stats.profile.name || '-'" />
                                    </span>
                                </span>
                                <div class="flex-shrink-0">
                                    <h6 class="medium f-15 mb-0">{{store.traderDetail.trader.stats.profile.name || '-'}}</h6>
                                    <p class="mb-0 line-1 gray small">{{dateFormat(social.dateCreated)}}</p>
                                </div>
                            </div>
                            <!-- <div class="position-relative dropdown" v-if="store.user.access_token && social.actions && social.actions.length">
                                <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item f-14 captilize" href="javascript:void(0)" v-for="list,key in social.actions" :key="key" @click="typeDelete(list.type,social,'socialType')">{{list.type}}</a></li>
                                </ul>
                            </div> -->
                            <div class="secondary f-12">
                                <span>{{$t('leader_profile.latestStatus')}}</span>
                            </div>
                        </div>
                        <div class="customPad viewNews"> 
                            <p class="mb-1 f-15 neutralGrey" v-if="social.comment " v-html="brTypesocial((showMoreSocialType == social.id)?social.comment:social.comment.slice(0,300)+(social.comment.length > 300?'...':''))"></p>
                            <a class="f-14 secondary" href="javascript:void(0)" @click="(showMoreSocialType == social.id)?(showMoreSocialType = ''):(showMoreSocialType = social.id)" v-if="social.comment.length > 300">
                                {{(showMoreSocialType == social.id)? $t('leader_profile.showLess') : $t('leader_profile.showMore') }}
                            </a>
                        
                            <ul class="commentArea d-flex align-items-center mt-2">
                                <li>
                                   <a class="d-flex align-items-center disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather type="heart" :fill="store.socialTypeCommentList.liked==true?'#ff7200':'none'" :stroke="store.socialTypeCommentList.liked==true?'#ff7200':'#778290'" @click="liked(store.socialTypeCommentList.liked,social.id,'socialType')" class=" w-16 me-1"></vue-feather ><span>{{store.socialTypeCommentList.likes || '0'}}</span></a>
                                </li>
                                <li>
                                    <a class="d-flex align-items-center disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather type="message-square" class=" w-16 me-1" @click="childComments=!childComments;socialTypeCommentAdd=!socialTypeCommentAdd"></vue-feather ><span>{{store.socialTypeCommentList.totalComments || '0'}}</span></a>
                                </li>
                                <li v-if="social.zulutradeEventId">
                                    <a class="d-flex align-items-center" href="javascript:void(0)" @click="sharePop=social.zulutradeEventId"><vue-feather  type="share-2" class="me-1 w-16 "></vue-feather ></a>
                                </li>
                            </ul>
                            <!-- Social type child comments -->
                            <div class="firstChild" v-if="(store.socialTypeCommentList.comments || []).length && childComments">
                                <div class="card-body border-0 mb-1" v-for="social,keyst in store.socialTypeCommentList.comments" :key="keyst">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2">
                                                <span v-if="social.fromZuluAccountId"><v-lazy-image  width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/webservices/Image.ashx?type=user&size=XL&id='+social.fromZuluAccountId+'&ignore=true'" :alt="social.publisher" :title="social.publisher" /></span>
                                                    <span v-else><v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" :alt="$t('copy_strategy.alt_title')" :title="$t('copy_strategy.alt_title')" /> </span>
                                            </span>
                                            <div class="flex-shrink-0">
                                                <h6 class="medium f-15 mb-0">{{social.publisher || '-'}}</h6>
                                                <p class="mb-0 line-1 gray small">{{dateFormat(social.dateCreated)}}</p>
                                            </div>
                                        </div>    
                                        <div class="position-relative dropdown disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" v-if="store.user.access_token && social.actions && social.actions.length">
                                            <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                                                <li v-for="list,indx in social.actions" :key="indx"><a v-if="list.type !='EDIT_INTEREST'" class="dropdown-item captilize f-14" href="javascript:void(0)" @click="typeDelete(list.type,social,'socialTypeChild');editSocialTypeChild=social.content;editSocialTypeChildID=social.socialEventId;topComment1='';socialTypeChildOptionType=list.type">{{(list.type=='TRANSLATE' && istrans.includes(social.socialEventId))? $t('leader_profile.original') :list.type.toLowerCase()}}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="customPad viewNews childBefore">
                                        <div class="newsfeed-card pt-3 shadow-none border" v-if="editSocialTypeChildID==social.socialEventId && socialTypeChildOptionType=='EDIT'">
                                            <div class="d-flex justify-content-start">
                                                <div class="writepost">
                                                    <textarea :class="[{'focused':focused}]" @blur="focused=false" @focus="focused=true" :placeholder="`${$t('leader_profile.postPlaceholderstart')} @ ${$t('leader_profile.postPlaceholderEnd')}`" data-height="80" v-model="editSocialTypeChild" @input="hastagAT1()"></textarea>
                                                </div>
                                            </div>
                                            <div v-if="store.socialTopCommentHastagName && store.socialTopCommentHastagName.length  && editSocialTypeChild">
                                                <ul class="autohasAt">
                                                    <li v-for="items,val in store.socialTopCommentHastagName" @click="pasteTagTopEdit(items.name)" :key="val" :id="items.name">{{removeSpace(items.name)}}</li>
                                                </ul>
                                            </div>
                                            <div class="button-container d-flex justify-content-end mt-1 align-items-center" v-if="editSocialTypeChild">
                                                <a class="secondary me-2 f-12" @click="editSocialTypeChildID=''" href="javascript:void(0)">Close</a>
                                                <button class="sendmessage align-items-center d-flex shadow-none" @click="commentPostEditSocialTypeChild(social.socialEventId)">
                                                    <v-lazy-image src="/assets/images/sendmessage.svg" alt="" width="15" height="15" />
                                                </button>
                                            </div>
                                        </div>
                                        <div v-else-if="social.translated && istrans.includes(social.socialEventId)">
                                            <p class="mb-1 f-14" v-html="brTypesocial((showMoreSocialTypeChild == social.socialEventId)?social.translated:social.translated.slice(0,300)+(social.translated.length > 300?'...':''))"></p>
                                            <a class="f-14 secondary" href="javascript:void(0)" @click="(showMoreSocialTypeChild == social.socialEventId)?(showMoreSocialTypeChild = ''):(showMoreSocialTypeChild = social.socialEventId)" v-if="social.translated.length > 300">
                                                {{(showMoreSocialTypeChild == social.socialEventId)? $t('leader_profile.showLess') : $t('leader_profile.showMore') }}
                                            </a>
                                        </div>
                                        <div v-else>
                                            <p class="mb-1 f-14" v-html="brTypesocial((showMoreSocialTypeChild == social.socialEventId)?social.content:social.content.slice(0,300)+(social.content.length > 300?'...':''))"></p>
                                            <a class="f-14 secondary" href="javascript:void(0)" @click="(showMoreSocialTypeChild == social.socialEventId)?(showMoreSocialTypeChild = ''):(showMoreSocialTypeChild = social.socialEventId)" v-if="social.content.length > 300">
                                                {{(showMoreSocialTypeChild == social.socialEventId)? $t('leader_profile.showLess') : $t('leader_profile.showMore') }}
                                            </a>
                                        </div>

                                        <ul class="commentArea d-flex align-items-center mt-2">
                                            <li>
                                                <a class="d-flex align-items-center disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather  type="heart" :fill="social.liked==true?'#ff7200':'none'" :stroke="social.liked==true?'#ff7200':'#778290'" @click="liked(social.liked,social.socialEventId,'socialTypeChild')" class=" w-16 me-1"></vue-feather >{{social.likes}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- Add Comment Social Type -->
                            <div class="newsfeed-card pt-3 shadow-none border" v-if="store.user.access_token && socialTypeCommentAdd">
                                <div class="d-flex justify-content-start position-relative">
                                    <div class="icon me-3">
                                        <a href="javascript:void(0)" class="avatar-frame ">
                                            <v-lazy-image class="rounded-circle d-block flex-none" v-if="Object.keys(store.userDetail || {}).length && store.userDetail.imageUrl" :src="store.userDetail.imageUrl" :alt="store.userDetail.profileName || ''" width="42" height="36"/>
                                            <span v-else class="userIcon me-3">{{(store.userDetail.firstName) ? store.userDetail.firstName.charAt(0) : ''}}{{(store.userDetail.lastName) ? store.userDetail.lastName.charAt(0) : ''}}</span>
                                            <!-- <v-lazy-image v-else class="rounded-circle d-block flex-none" src="/assets/images/profile-pic.png" alt="" width="42" height="42"/> -->
                                        </a>
                                    </div>
                                    <div class="writepost">
                                        <textarea :class="[{'focused':focused}]" class="f-14" @blur="focused=false" @focus="focused=true" :placeholder="`${$t('leader_profile.postPlaceholderstart')} @ ${$t('leader_profile.postPlaceholderEnd')}`" data-height="80" v-model="topComment1" @input="hastagAT1()"></textarea>
                                    </div>
                                </div>
                                <div class="p-2 bg-white box-shadow boxed border position-absolute" v-if="store.socialTopCommentHastagName && store.socialTopCommentHastagName.length && topComment1">
                                    <ul class="autohasAt m-0">
                                        <li v-for="item,stkey in store.socialTopCommentHastagName" @click="pasteTagType(item.name)" :key="stkey" :id="item.name" class="pointer f-14">{{removeSpace(item.name)}}</li>
                                    </ul>
                                </div>
                                <div class="button-container d-flex justify-content-end mt-1" v-if="topComment1">
                                    <button class="sendmessage align-items-center d-flex shadow-none" @click="commentTypePost(social.id)">
                                        <v-lazy-image  src="/assets/images/sendmessage.svg" alt="" width="15" height="15" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Social comments -->
                <div class="mb-3" v-if="(store.socialList.content || []).length > 0">
                    <div class="bg-white boxed radius12 card-body mb-2" v-for="social,key in store.socialList.content" :key="key">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="d-flex align-items-center">
                                <span class="me-2">
                                    <!-- <router-link v-if="social.providerId" :to="'/trader/'+social.providerId+'/trading?t=10000&m=1'">
                                        <span v-if="social.publisherAvatar"><v-lazy-image  width="40" height="40" class="rounded-circle d-block" :src="social.publisherAvatar" :alt="social.publisher" :title="social.publisher" /></span>
                                        <span v-else>
                                            <v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" alt="ZuluTrade" title="ZuluTrade" />
                                        </span>
                                    </router-link> -->
                                    <span >
                                        <span v-if="social.publisherAvatar"><v-lazy-image  width="40" height="40" class="rounded-circle d-block" :src="social.publisherAvatar" :alt="social.publisher" :title="social.publisher" /></span>
                                        <span v-else>
                                            <v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" :alt="$t('copy_strategy.alt_title')" :title="$t('copy_strategy.alt_title')" />
                                        </span>
                                    </span>
                                </span>
                                <!-- <span class="me-2" v-else>
                                    <span v-if="social.publisher == 'Market News'" ><v-lazy-image width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/ngzassets/img/newsfeed/marketnews.png'" :alt="social.publisher" :title="social.publisher" /></span>
                                    <span v-else-if="social.publisher == 'ZuluTrade News'"><v-lazy-image width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/ngzassets/img/newsfeed/zulutradenews.png'" :alt="social.publisher" :title="social.publisher" /></span>
                                    <span v-else-if="social.fromZuluAccountId"><v-lazy-image width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/webservices/Image.ashx?type=user&size=XL&id='+social.fromZuluAccountId+'&ignore=true'" :alt="social.publisher" :title="social.publisher" /></span>
                                    <span v-else><v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" alt="ZuluTrade" title="ZuluTrade" /></span>
                                </span> -->
                                <div class="flex-shrink-0">
                                    <!-- <router-link :to="'/trader/'+social.providerId+'/trading?t=10000&m=1'" v-if="social.providerId"><h6 class="medium f-15 mb-0">{{social.publisher || '-'}}</h6></router-link> -->
                                    <!-- && social.providerId != store.traderDetail.trader.stats.providerId -->
                                    <h6 class="medium f-15 mb-0">{{social.publisher || '-'}}</h6>
                                    <p class="mb-0 line-1 gray small">{{dateFormat(social.time)}}</p>
                                </div>
                            </div>   
                            <div class="position-relative dropdown disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" v-if="store.user.access_token && social.actions && social.actions.length">
                                <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                                    <li v-for="list,keys in social.actions" :key="keys"><a class="dropdown-item f-14 captilize" href="javascript:void(0)" @click="typeDelete(list.type,social,'social');editSocial=social.content;editSocialID=social.socialEventId;topComment='';socialParentOptionType=list.type;socialAttachment=social.attachmentUrl" v-if="list.type !='EDIT_INTEREST'">
                                        {{(list.type=='TRANSLATE' && istrans.includes(social.socialEventId))? $t('leader_profile.original') :list.type.toLowerCase()}}
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="customPad viewNews">
                            <div class="newsfeed-card pt-3 shadow-none border" v-if="editSocialID==social.socialEventId && socialParentOptionType=='EDIT'">
                                <div class="d-flex justify-content-start">
                                    <div class="writepost">
                                        <textarea :class="[{'focused':focused}]" @blur="focused=false" @focus="focused=true" :placeholder="`${$t('leader_profile.postPlaceholderstart')} @ ${$t('leader_profile.postPlaceholderEnd')}`" data-height="80" v-model="editSocial" @input="hastagAT()"></textarea>
                                    </div>
                                </div>
                                <div class="p-2 searchAutohasAt bg-white box-shadow boxed border position-absolute" v-if="store.socialTopCommentHastagName && store.socialTopCommentHastagName.length && editSocial">
                                    <ul class="autohasAt">
                                        <li v-for="items,skey in store.socialTopCommentHastagName" :key="skey" :id="items.name" @click="pasteTagGeneralEdit(items.name)" class="pointer f-14">{{removeSpace(items.name)}}</li>
                                    </ul>
                                </div>
                                <div class="button-container d-flex align-items-center justify-content-between mt-1" v-if="editSocial">
                                    <div class="d-flex align-items-center" v-if="socialAttachment">
                                        <div class="uploadDocument_preview ms-0">
                                            <video v-if="(socialAttachment).includes('.mp4')" class="w-100" :src="socialAttachment" :title="$t('leader_profile.uploadedVideo')" :alt="$t('leader_profile.uploadedVideo')"></video>
                                            <span v-else><v-lazy-image  class="w-100" :src="socialAttachment" :title="$t('leader_profile.uploadedImage')" :alt="$t('leader_profile.uploadedImage')" /></span>
                                        </div>
                                        <a @click.stop="socialAttachment=''" href="javascript:void(0)" class="trashNull">
                                            <vue-feather size="18" type="trash-2"></vue-feather>
                                        </a>
                                    </div>
                                    <div class="d-flex align-items-center" v-else-if="commentImageEdit.name">
                                        <div class="uploadDocument_preview ms-0">
                                            <video v-if="(commentImageEdit.type).includes('video')" class="w-100" :src="commentImageSRCEdit" :title="$t('leader_profile.uploadedVideo')" :alt="$t('leader_profile.uploadedVideo')"></video>
                                            <span v-else ><v-lazy-image class="w-100" :src="commentImageSRCEdit" :title="$t('leader_profile.uploadedImage')" :alt="$t('leader_profile.uploadedImage')"/></span>
                                        </div>
                                        <a @click.stop="commentImageEdit={};commentImageSRCEdit=''" href="javascript:void(0)" class="trashNull">
                                            <vue-feather size="18" type="trash-2"></vue-feather>
                                        </a>
                                    </div>
                                    <div v-else class="d-flex align-items-center feedWirteSsomething ps-0">
                                        <div class="upload-btn-wrapper pointer me-2 me-md-3">
                                            <button class="btn d-flex align-items-center"><v-lazy-image class="me-2" src="/assets/images/uploadIcon.png" :alt="$t('leader_profile.uploadImage')" /> {{$t('leader_profile.photo')}}</button>
                                            <input type="file" name="myImagefile" id="myImagefile" accept="image/*" @change="uploadMedia($event)"/>
                                        </div>
                                        <div class="upload-btn-wrapper pointer">
                                            <button class="btn d-flex align-items-center"><v-lazy-image class="me-2" src="/assets/images/uloadPlay.png" :alt="$t('leader_profile.uploadVideo')" /> {{$t('leader_profile.video')}}</button>
                                            <input type="file" name="myVideofile" id="myVideofile" accept="video/mp4" @change="uploadMedia($event)"/>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <a class="secondary me-2 f-12" @click="editSocialID=''" href="javascript:void(0)">Close</a>
                                        <button class="sendmessage align-items-center d-flex shadow-none" @click="commentPostEdit(social.socialEventId)">
                                            <v-lazy-image class="" src="/assets/images/sendmessage.svg" alt="" width="15" height="15" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="social.translated && istrans.includes(social.socialEventId)">
                                <p class="mb-1 f-15 neutralGrey" v-html="findHastag((showMoreSocial == social.socialEventId)?social.translated:social.translated.slice(0,300)+(social.translated.length > 300?'...':''),social.providerId)"></p>
                                <a class="f-14 secondary" href="javascript:void(0)" @click="(showMoreSocial == social.socialEventId)?(showMoreSocial = ''):(showMoreSocial = social.socialEventId)" v-if="social.translated.length > 300">
                                    {{(showMoreSocial == social.socialEventId)? $t('leader_profile.showLess') : $t('leader_profile.showMore') }}
                                </a>
                            </div>
                            <div class="py-1" v-else>
                                <video style="width:100%" height="400" class="controlsVideo br-10 my-3" controls :src="social.attachmentUrl" v-if="social.attachmentUrl && social.attachmentUrl.indexOf('mp4') !== -1"></video>
                                <span v-else-if="social.attachmentUrl && social.attachmentUrl.indexOf('mp4') == -1" >
                                    <v-lazy-image class="my-3 br-10 pointer" :src="social.attachmentUrl" :alt="$t('copy_strategy.alt_title')" style="height:300px" @click="lightboxURL = social.attachmentUrl"/>
                                </span>
                                <p class="mb-1 f-15 neutralGrey" v-html="findHastag((showMoreSocial == social.socialEventId)?social.content:social.content.slice(0,300)+(social.content.length > 300?'...':''),social.providerId)"></p>
                                <a class="f-14 secondary" href="javascript:void(0)" @click="(showMoreSocial == social.socialEventId)?(showMoreSocial = ''):(showMoreSocial = social.socialEventId)" v-if="social.content.length > 300">
                                    {{(showMoreSocial == social.socialEventId)? $t('leader_profile.showLess') : $t('leader_profile.showMore') }}
                                </a>
                            </div>
                            <div v-if="social.link">
                                <a :href="social.link.url" target="_blank">
                                    <div class="d-flex border align-items-center mt-2 bg-light rounded overflow-hidden">
                                        <v-lazy-image class="bg-white p-3 border-right" :alt="social.link.imageAlt" :src="social.link.image" :title="social.link.title" height="150" width="150" />
                                        <div class="ps-4">
                                            <p class="f-12 mb-0">{{social.link.domain}}</p>
                                            <p class="f-14 medium f-15 mb-0">{{social.link.title}}</p>
                                            <p class="f-12 mb-0">{{social.link.desc}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <ul class="commentArea d-flex align-items-center mt-2">
                                <li>
                                    <a class="d-flex align-items-center disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather type="heart" :fill="social.liked==true?'#ff7200':'none'" :stroke="social.liked==true?'#ff7200':'#778290'" @click="liked(social.liked,social.socialEventId,'social')" class=" w-16 me-1"></vue-feather > {{social.likes}}</a>
                                </li>
                                <li>
                                    <a v-if="$route.name=='socialFeed' || $route.name=='myPosts'" :class="{'disabled' : store.customerDetail?.readOnly}" class="d-flex align-items-center disable-bg" href="javascript:void(0)" @click="socialComment(social.eventId,social.totalComments)"><vue-feather type="message-square" class=" w-16 me-1"></vue-feather > {{social.totalComments}}</a>
                                    <a v-else class="d-flex align-items-center disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" href="javascript:void(0)" @click="socialComment(social.eventId,social.totalComments)"><vue-feather type="message-square" class=" w-16 me-1"></vue-feather > {{social.totalComments}}</a>
                                </li>
                                <li>
                                    <a class="d-flex align-items-center" href="javascript:void(0)" @click="sharePop=social.eventId"><vue-feather  type="share-2" class="me-1 w-16 "></vue-feather ></a>
                                </li>
                            </ul>
                            <!-- Social child comments -->
                            <div class="ntChild" v-if="social.comments && social.comments.length && commentAdd==social.eventId">
                                <div class="card-body border-0 mb-1" v-for="item,keysc in social.comments" :key="keysc">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2">
                                                <span v-if="item.fromZuluAccountId"><v-lazy-image width="40" height="40" class="rounded-circle d-block" :src="static_vars.imagesURL+'/webservices/Image.ashx?type=user&size=XL&id='+item.fromZuluAccountId+'&ignore=true'" :alt="item.publisher" :title="item.publisher" /></span>
                                                <span v-else><v-lazy-image width="40" height="40" class="rounded-circle d-block" src="/assets/images/profile-pic.png" :alt="$t('copy_strategy.alt_title')" :title="$t('copy_strategy.alt_title')" /></span>
                                            </span>
                                            <div class="flex-shrink-0">
                                                <h6 class="medium f-15 mb-0">{{item.publisher || '-'}}</h6>
                                                <p class="mb-0 line-1 gray small">{{dateFormat(item.dateCreated)}}</p>
                                            </div>
                                        </div>      
                                        <div class="position-relative dropdown disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" v-if="store.user.access_token && item.actions && item.actions.length">
                                            <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                                                <li v-for="childList,sckey in item.actions" :key="sckey"><a v-if="childList.type !='EDIT_INTEREST'" class="dropdown-item f-14 captilize" href="javascript:void(0)" @click="typeDelete(childList.type,item,'socialChild');editCommentSocial=item.content;editCommentSocialID=item.socialEventId;topComment2='';socialOptionType=childList.type">
                                                    {{(childList.type=='TRANSLATE' && istrans.includes(item.socialEventId))? $t('leader_profile.original') :childList.type.toLowerCase()}}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="customPad viewNews">
                                        <div class="newsfeed-card pt-3 shadow-none border" v-if="editCommentSocialID==item.socialEventId && socialOptionType=='EDIT'">
                                            <div class="d-flex justify-content-start">
                                                <div class="writepost">
                                                    <textarea :class="[{'focused':focused}]" @blur="focused=false" @focus="focused=true" :placeholder="`${$t('leader_profile.postPlaceholderstart')} @ ${$t('leader_profile.postPlaceholderEnd')}`" data-height="80" v-model="editCommentSocial" @input="hastagAT2()"></textarea>
                                                </div>
                                            </div>
                                            <div class="p-2 searchAutohasAt bg-white box-shadow boxed border position-absolute" v-if="store.socialTopCommentHastagName && store.socialTopCommentHastagName.length  && editCommentSocial">
                                                <ul class="autohasAt">
                                                    <li v-for="items,keysct in store.socialTopCommentHastagName" :key="keysct" :id="items.name" @click="pasteTagGeneralEditComment(items.name)" class="pointer f-14">{{removeSpace(items.name)}}</li>
                                                </ul>
                                            </div>
                                            <div class="button-container d-flex justify-content-end mt-1 align-items-center" v-if="editCommentSocial">
                                                <a class="secondary me-2 f-12" @click="editCommentSocialID=''" href="javascript:void(0)">Close</a>
                                                <button class="sendmessage align-items-center d-flex shadow-none" @click="commentPostEditChild(item.socialEventId,social.eventId)">
                                                    <v-lazy-image src="/assets/images/sendmessage.svg" alt="" width="15" height="15" />
                                                </button>
                                            </div>
                                        </div>
                                        <div v-else-if="item.translated && istrans.includes(item.socialEventId)">
                                            <p class="mb-1 f-15 neutralGrey" v-html="findHastag((showMoreSocialChild == item.socialEventId)?item.translated:item.translated.slice(0,300)+(item.translated.length > 300?'...':''),item.providerId)"></p>
                                            <a class="f-14 secondary" href="javascript:void(0)" @click="(showMoreSocialChild == item.socialEventId)?(showMoreSocialChild = ''):(showMoreSocialChild = item.socialEventId)" v-if="item.translated.length > 300">
                                                {{(showMoreSocialChild == item.socialEventId)? $t('leader_profile.showLess') : $t('leader_profile.showMore') }}
                                            </a>
                                        </div>
                                        <div v-else>
                                            <p class="mb-1 f-15 neutralGrey" v-html="findHastag((showMoreSocialChild == item.socialEventId)?item.content:item.content.slice(0,300)+(item.content.length > 300?'...':''),item.providerId)"></p>
                                            <a class="f-14 secondary" href="javascript:void(0)" @click="(showMoreSocialChild == item.socialEventId)?(showMoreSocialChild = ''):(showMoreSocialChild = item.socialEventId)" v-if="item.content.length > 300">
                                                {{(showMoreSocialChild == item.socialEventId)? $t('leader_profile.showLess') : $t('leader_profile.showMore') }}
                                            </a>
                                        </div>

                                        <ul class="commentArea d-flex align-items-center mt-2">
                                            <li>
                                                <a class="d-flex align-items-center disable-bg" :class="{'disabled' : store.customerDetail?.readOnly}" href="javascript:void(0)"><vue-feather  type="heart" :fill="item.liked==true?'#ff7200':'none'" :stroke="item.liked==true?'#ff7200':'#778290'" @click="liked(item.liked,item.socialEventId,'socialChild')" class=" w-16 me-1"></vue-feather >{{item.likes}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- Add Comment General Social -->
                            <div class="newsfeed-card pt-3 shadow-none border" v-if="store.user.access_token && commentAdd==social.eventId">
                                <div class="d-flex justify-content-start position-relative">
                                    <div class="icon me-3">
                                        <a href="javascript:void(0)" class="avatar-frame ">
                                            <v-lazy-image class="rounded-circle d-block flex-none" v-if="Object.keys(store.userDetail || {}).length && store.userDetail.imageUrl" :src="store.userDetail.imageUrl" :alt="store.userDetail.profileName || ''" width="42" height="36" />
                                            <span v-else class="userIcon me-3">{{(store.userDetail.firstName) ? store.userDetail.firstName.charAt(0) : ''}}{{(store.userDetail.lastName) ? store.userDetail.lastName.charAt(0) : ''}}</span>
                                            <!-- <v-lazy-image v-else class="rounded-circle d-block flex-none" src="/assets/images/profile-pic.png" alt="" width="42" height="42" /> -->
                                        </a>
                                    </div>
                                    <div class="writepost">
                                        <textarea :class="[{'focused':focused}]" class="f-14" @blur="focused=false" @focus="focused=true" :placeholder="`${$t('leader_profile.postPlaceholderstart')} @ ${$t('leader_profile.postPlaceholderEnd')}`" data-height="80" v-model="topComment2" @input="hastagAT2()"></textarea>
                                    </div>
                                </div>
                                <div class="p-2 bg-white box-shadow boxed border position-absolute" v-if="store.socialTopCommentHastagName && store.socialTopCommentHastagName.length  && topComment2">
                                    <ul class="autohasAt">
                                        <li v-for="item,keygn in store.socialTopCommentHastagName" @click="pasteTagGeneral(item.name)" :key="keygn" :id="item.name" class="pointer f-14">{{removeSpace(item.name)}}</li>
                                    </ul>
                                </div>
                                <div class="button-container d-flex justify-content-end mt-1" v-if="topComment2">
                                    <button class="sendmessage align-items-center d-flex shadow-none"  @click="commentPost(social.socialEventId,social.eventId)">
                                        <v-lazy-image class="" src="/assets/images/sendmessage.svg" alt="" width="15" height="15" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="text-center"><a v-if="store.socialList.includesLast==false" @click="loadMore()" class="button fillBtn zulu_btn mt-4 px-md-4" href="javascript:void(0);"><Spinner class="buttonSpiner" v-if="store.singleLoading"></Spinner> {{$t('leader_profile.loadMore')}}</a></div>
                </div>
                <Nodata v-if="!($route.name=='socialFeed' || $route.name=='myPosts') && !(Object.keys(store.socialTypeList).length && store.socialTypeList.content && store.socialTypeList.content.length > 0) && !(Object.keys(store.socialList).length && store.socialList.content && store.socialList.content.length > 0)"></Nodata>
            </div>
        </div>
    </div>
    <sharePop :shareURL="sharePop" :close="()=>sharePop = ''" v-if="sharePop" :shareType="'socialFeed'"></sharePop>

    <!-- Lightbox -->
    <div class="modal show fade" style="display: block;" v-if="lightboxURL">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <button type="button" class="btn-close" @click="lightboxURL=''"></button>
                </div>
                <div class="modal-body p-0 border-bottom text-center">
                    <v-lazy-image class="d-block mx-auto" :src="lightboxURL" :alt="$t('leader_profile.lightbox')"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery"
import { myStore } from "@/store/pinia-store";
import sharePop from "@/views/social-feed/share-pop"
import moment from "moment";
import _ from 'lodash'
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    components: { sharePop },
    data(){
        return {
            selectedHastag: '',
            sharePop: '',
            childComments: false,
            childlessMoreID: 0,
            lessMoretext: false,
            focused: false,
            topComment: '',
            topComment1: '',
            topComment2: '',
            commentAdd: '',
            likeUnlike: '',
            showLoginPopup: false,
            socialTypeCommentAdd: false,
            childSocialComment: '',
            
            showMoreSocial: '',
            showMoreSocialChild: '',
            showMoreSocialType: '',
            showMoreSocialTypeChild: '',
            
            editSocial: '',
            editSocialID: '',
            socialParentOptionType: '',
            editCommentSocial: '',
            editCommentSocialID: '',
            socialOptionType: '',
            editSocialTypeChild: '',
            editSocialTypeChildID: '',
            socialTypeChildOptionType: '',
            loadMoreLoading: false,
            loadMorehastag: false,
            istrans: [],
            commentImage: {},
            commentImageSRC: '',
            commentImageEdit: {},
            commentImageSRCEdit: '',
            mediaError: '',
            socialAttachment: '',
            lightboxURL: '',
        }
    },
    props: ["feedType","rightHastag","clearRightHastag"],
    watch:{
        'rightHastag'(v){
            if(v){
                this.selectedHastag = v;
                this.clearRightHastag()
            }
        }
    },
    methods: {
        copyClipboard(id){
            console.log(id)
        },
        dateFormat(date){
            let format = moment(date).toString()
            return moment(format).fromNow()
        },
        loadMore(){
            this.loadMoreLoading = true
            if(this.store.socialList.content.length > 0 && !this.store.socialList.includesLast){
                let lastEventID = this.store.socialList.content[this.store.socialList.content.length - 1]
                if(this.selectedHastag){
                    if(this.$route.name=='socialFeed' || this.$route.name=='myPosts'){
                        if(this.feedType=='1') {
                            this.store.callSocialFeedPageHastagLoadMoreList({},true,lastEventID.eventId,this.selectedHastag).then(()=>{
                                this.loadMoreLoading = false
                            })
                        } else if(this.feedType=='3') {
                            this.store.callSocialFeedPageHastagLoadMoreList({},true,lastEventID.eventId,this.selectedHastag,'noAuth').then(()=>{
                                this.loadMoreLoading = false
                            })
                        } else {
                            this.store.callMyFeedPageHastagLoadMoreList({},true,lastEventID.eventId,this.selectedHastag).then(()=>{
                                this.loadMoreLoading = false
                            })
                        }
                    }else if(this.$route.name == 'dashboard_portfolio'){
                        this.store.callSocialHastagLoadMoreList({},true,this.store.userSelectedAccount.providerId,lastEventID.eventId,this.selectedHastag).then(()=>{
                            this.loadMoreLoading = false
                        })
                    }else {
                        this.store.callSocialHastagLoadMoreList({},true,this.$route.params.id,lastEventID.eventId,this.selectedHastag).then(()=>{
                            this.loadMoreLoading = false
                        })
                    }
                } else {
                    if(this.$route.name=='socialFeed' || this.$route.name=='myPosts'){
                        if(this.feedType=='1') {
                            this.store.callSocialFeedPageLoadMoreList({},true,lastEventID.eventId).then(()=>{
                                this.loadMoreLoading = false
                            })
                        }
                        else if(this.feedType=='3') { 
                            this.store.callSocialFeedPageLoadMoreList({},true,lastEventID.eventId,'noAuth').then(()=>{
                                this.loadMoreLoading = false
                            })
                        }
                        else if(this.feedType=='2'){
                            this.store.callMyFeedPageLoadMoreList({},true,lastEventID.eventId).then(()=>{
                                this.loadMoreLoading = false
                            })
                        }
                    } else if(this.$route.name == 'dashboard_portfolio'){
                        this.store.callSocialLoadMoreList({},true,this.store.userSelectedAccount.providerId,lastEventID.eventId).then(()=>{
                            this.loadMoreLoading = false
                        })
                    } else {
                        this.store.callSocialLoadMoreList({},true,this.$route.params.id,lastEventID.eventId).then(()=>{
                            this.loadMoreLoading = false
                        })
                    }
                }
            }
        },
        findHastag(text,prID){
            let hastag = text.replace(/#(\w+)/g, '<a href="javascript:void(0)" class="menutext medium hashlink">#$1</a>');
            let hasAt = hastag.replace(/@(\w+)/g, '<a href="javascript:void(0)" id="'+prID+'" class="menutext medium traderlink">@$1</a>');
            let thirdLink = hasAt.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" class="menutext medium thirdlink">$1</a>');
            let br = thirdLink.replace(/\\r\\n/g, "<br />")
            return br.trim()
        },
        brTypesocial(text){
            let hastag = text.replace(/#(\w+)/g, '<a href="javascript:void(0)" class="menutext medium hashlink">#$1</a>');
            let thirdLink = hastag.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" class="menutext medium thirdlink">$1</a>');
            let br = thirdLink.replace(/\\r\\n/g, "<br />")
            return br.trim();
        },
        removeSpace(text){
            text = text.replace(/\s/g, '')
            return text
        },
        generalSocialList(){
            if(this.$route.name=='socialFeed'){
                if(this.feedType=='3') { 
                    this.store.callSocialFeedPageList({},true,'noAuth')
                } else {
                    this.store.callSocialFeedPageList({},true)
                }
            } else if(this.$route.name=='myPosts'){
                this.store.callmyFeedPageList({},true)
            } else if(this.$route.name == 'dashboard_portfolio'){
               this.store.callSocialList({},false,this.store.userSelectedAccount.providerId)
             } else {
                this.store.callSocialList({},true,this.$route.params.id)
            }
        },
        socialComment(id,totalComments){
            // this.store.$patch({socialCommentList : []})
            if(this.$route.name=='socialFeed' || this.$route.name=='myPosts'){
                if(!this.store.user.access_token && totalComments=='0'){
                    this.showLoginPopup=true
                } else {
                    if(id && this.commentAdd != id){
                        this.commentAdd=id;
                        this.store.callSocialCommentList({},false,id)
                    }else{ this.commentAdd=''; }
                }
            } else {
                if(this.store.user.access_token){
                    if(id && this.commentAdd != id){
                        this.commentAdd=id;
                        this.store.callSocialCommentList({},false,id)
                    }else{ this.commentAdd=''; }
                } else {
                    this.showLoginPopup=true
                }
            }
        },
        getWord (s, pos) {
            var n = s.substring(pos).match(/^[a-zA-Z0-9-_@#]+/)
            var p = s.substring(0, pos).match(/[a-zA-Z0-9-_@#]+$/)
            if(!p && !n) return ''
            return (p || '') + (n || '')
        },
        hastagAT: _.debounce(function () {
            if(this.topComment || this.editSocial){
                var has = ''
                if(this.topComment){
                    has = this.topComment.split(' ').pop()
                    // has = this.getWord(e.target.value, e.target.selectionStart)
                    this.editSocial = ''
                }else if(this.editSocial){
                    this.topComment = ''
                    has = this.editSocial.split(' ').pop()
                }
                var lastelemnt = has.startsWith("@", 0)
                var lastelemnt1 = has.startsWith("#", 0)
                if(lastelemnt){
                    if(has.length > 2){
                        has = has.replace('@','')
                        this.store.callSocialTopCommentPublicName({},false,has)
                    }
                } else if(lastelemnt1){
                    if(has.length > 2){
                        has = has.replace('#','')
                        this.store.callSocialTopCommentHastagName({},false,has)
                    }
                } else{
                    this.store.$patch({socialTopCommentHastagName : []})
                }
            } else {
                this.store.$patch({socialTopCommentHastagName : []})
            }
        }, 500),
        hastagAT1: _.debounce(function () {
            if(this.topComment1 || this.editSocialTypeChild){
                var has1 = ''
                if(this.topComment1){
                    has1 = this.topComment1.split(' ').pop()
                    this.editSocialTypeChild = ''
                }else if(this.editSocialTypeChild){
                    this.topComment1 = ''
                    has1 = this.editSocialTypeChild.split(' ').pop()
                }
                var lastelemnt = has1.startsWith("@", 0)
                var lastelemnt1 = has1.startsWith("#", 0)
                if(lastelemnt){
                    if(has1.length > 2){
                        has1 = has1.replace('@','')
                        this.store.callSocialTopCommentPublicName({},false,has1)
                    }
                } else if(lastelemnt1){
                    if(has1.length > 2){
                        has1 = has1.replace('#','')
                        this.store.callSocialTopCommentHastagName({},false,has1)
                    }
                } else{
                    this.store.$patch({socialTopCommentHastagName : []})
                }
            } else {
                this.store.$patch({socialTopCommentHastagName : []})
            }
        }, 500),
        hastagAT2: _.debounce(function () {
             if(this.topComment2 || this.editCommentSocial){
                var has2 = ''
                if(this.topComment2){
                    has2 = this.topComment2.split(' ').pop()
                    this.editCommentSocial = ''
                }else if(this.editCommentSocial){
                    this.topComment2 = ''
                    has2 = this.editCommentSocial.split(' ').pop()
                }
                var lastelemnt = has2.startsWith("@", 0)
                var lastelemnt1 = has2.startsWith("#", 0)
                if(lastelemnt){
                    if(has2.length > 2){
                        has2 = has2.replace('@','')
                        this.store.callSocialTopCommentPublicName({},false,has2)
                    }
                } else if(lastelemnt1){
                    if(has2.length > 2){
                        has2 = has2.replace('#','')
                        this.store.callSocialTopCommentHastagName({},false,has2)
                    }
                } else{
                    this.store.$patch({socialTopCommentHastagName : []})
                }
            } else {
                this.store.$patch({socialTopCommentHastagName : []})
            }
        }, 500),
        liked(elem,socialEventId,type){
            if(this.store.user.access_token){
                if(elem){
                    this.likeUnlike = 'unlike'
                    if(type=='socialType'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'socialType')
                    } else if(type=='social'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'social')
                    } else if(type=='socialChild'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'socialChild')
                    } else if(type=='socialTypeChild'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'socialTypeChild')
                    }
                } else {
                    this.likeUnlike = 'like'
                    if(type=='socialType'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'socialType')
                    } else if(type=='social'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'social')
                    } else if(type=='socialChild'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'socialChild')
                    } else if(type=='socialTypeChild'){
                        this.store.callSocialLikeUnlike({},false,socialEventId,this.likeUnlike,'socialTypeChild')
                    }
                }
            } else {
                this.showLoginPopup = true
            }
        },
        typeDelete(type,social,listType){
            if(type=='DELETE'){
                this.store.deleteSocialComment({},false,social.socialEventId,listType)
            } else if(type=='REPORT'){
                let postData = {
                    "source": "TRADER",
                    "type": "REPORT",
                    "parentId": social.socialEventId,
                    "parentType": "COMMENT",
                    "toZuluAccountId": social.fromZuluAccountId,
                    "fromZuluAccountId": this.store.userSelectedAccount.zuluAccountId
                }
                this.store.reportComment(postData,false,social.socialEventId,listType)
            } else if(type=='TRANSLATE'){
                const checkKeys = {
                    'social':'socialList',
                    'socialType':'socialTypeList',
                    'socialChild':'socialCommentList',
                    'socialTypeChild':'socialTypeCommentList'
                }
                const checkKeys2 = {
                    'social':'content',
                    'socialType':'content',
                    'socialChild':'comments',
                    'socialTypeChild':'comments'
                }
                const find = this.store[checkKeys[listType]][checkKeys2[listType]].find(v=>v.socialEventId == social.socialEventId)
                if(find && find.translated){
                    let myIndex = this.istrans.indexOf(social.socialEventId)
                    if(myIndex == -1){
                        this.istrans.push(social.socialEventId)
                    }else{
                        this.istrans.splice(myIndex, 1)
                    }
                } else{
                    this.store.socialTranslate({"text": social.content,"to": "en"},false,social.socialEventId,listType).then(()=>{
                        this.istrans.push(social.socialEventId)
                    })
                }
            }
        },
        commentTopPost(){
            if(this.topComment){
                    let postData = new FormData();
                    if(this.commentImage.name){
                        postData.append('attachment',this.commentImage)
                    }
                    postData.append('comment',this.topComment)
                    postData.append('source','TRADER')
                    postData.append('type','COMMENT')
                    postData.append('toZuluAccountId',(this.$route.name=='socialFeed' || this.$route.name == 'dashboard_portfolio' || this.$route.name=='myPosts') ? this.store.userSelectedAccount.zuluAccountId : this.store.traderDetail.trader.stats.profile.zuluAccountId)
                    this.store.postSocialTopComment(postData,false).then(()=>{
                        this.commentImage = {}
                        this.commentImageSRC = ''
                        if(this.$route.name=='socialFeed' || this.$route.name=='myPosts'){
                            if(this.feedType=='1') {
                                setTimeout(()=>{this.store.callSocialFeedPageList({},true,'noAuth')}, 1000);
                            } else if(this.feedType=='3') { 
                                setTimeout(()=>{this.store.callSocialFeedPageList({},true,'noAuth')}, 1000);
                            } else {
                                setTimeout(()=>{this.store.callmyFeedPageList({},true)}, 1000);
                            }
                        }else if(this.$route.name == 'dashboard_portfolio'){
                            setTimeout(()=>{this.store.callSocialList({},false,this.store.userSelectedAccount.providerId)}, 1000);
                        } else {
                            setTimeout(()=>{this.store.callSocialList({},false,this.$route.params.id)}, 1000);
                        }
                    })
                    this.mediaError = ''
            }
            this.topComment = ''
        },
        commentTypePost(socialEventId){
            if(this.topComment1){
                let postData = new FormData();
                postData.append('content', this.topComment1)
                this.store.postSocialComment(postData,false,socialEventId).then(()=>{
                    this.store.callSocialTypeList({},false,this.store.userSelectedAccount.zuluAccountId)
                })
            }
            this.topComment1 = ''
        },
        commentPost(socialEventId,eventId){
            if(this.topComment2){
                let postData = new FormData();
                postData.append('content', this.topComment2)
                this.store.postSocialComment(postData,false,socialEventId).then(()=>{
                    this.store.callSocialCommentList({},false,eventId)
                })
            }
            this.topComment2 = ''
        },
        commentPostEditChild(socialEventId){
            if(this.editCommentSocial){
                let postData = new FormData();
                postData.append('content', this.editCommentSocial)
                this.store.postSocialCommentEdit(postData,false,socialEventId).then(()=>{
                    this.generalSocialList()
                })
            }
            this.editCommentSocialID = ''
        },
        commentPostEdit(socialEventId){
            if(this.editSocial){
                let postData = new FormData();
                if(this.commentImageEdit.name){
                    postData.append('attachment',this.commentImageEdit)
                }
                postData.append('content',this.editSocial)
                this.store.postSocialCommentEdit(postData,false,socialEventId).then(()=>{
                    this.editSocialID = ''
                    this.commentImageEdit = {}
                    this.commentImageSRCEdit = ''
                    if(this.$route.name=='socialFeed' || this.$route.name=='myPosts'){
                        if(this.feedType=='1') {
                            setTimeout(()=>{this.store.callSocialFeedPageList({},true)}, 1000);
                        } else if(this.feedType=='3') {
                            setTimeout(()=>{this.store.callSocialFeedPageList({},true,'noAuth')}, 1000);
                        } else {
                            setTimeout(()=>{this.store.callmyFeedPageList({},true)}, 1000);
                        }
                    }else if(this.$route.name == 'dashboard_portfolio'){
                        setTimeout(()=>{this.store.callSocialList({},false,this.store.userSelectedAccount.providerId)}, 1000);
                    } else {
                        setTimeout(()=>{this.store.callSocialList({},false,this.$route.params.id)}, 1000);
                    }
                })
            }
        },
        commentPostEditSocialTypeChild(socialEventId){
            if(this.editSocialTypeChild){
                let postData = new FormData();
                postData.append('content', this.editSocialTypeChild)
                this.store.postSocialCommentEdit(postData,false,socialEventId).then(()=>{
                    this.store.callSocialTypeList({},false,this.store.userSelectedAccount.zuluAccountId)
                })
            }
            this.editSocialTypeChildID = ''
        },
        // removeFirstWord(type){
        //     if(type=='EDIT'){
        //         let editstr = this.editSocial.split(" ")
        //         if(editstr[0].charAt(0) == '@'){
        //             this.editSocial = this.editSocial.substr(this.editSocial.indexOf(" ") + 1)
        //         }
        //     }
        // },
        pasteTagTop(hastag){
            hastag =  this.removeSpace(hastag)
            let lastWord = this.topComment.split(' ').pop()
            this.topComment = this.topComment.replace(lastWord, '') 
            var lastelemnt = lastWord.startsWith("@", 0)
            var lastelemnt1 = lastWord.startsWith("#", 0)
            if(lastelemnt){
                this.topComment = this.topComment + '@'+hastag
            } else if(lastelemnt1){
                this.topComment = this.topComment + '#'+hastag
            }
            this.store.$patch({socialTopCommentHastagName : []})
        },
        pasteTagTopEdit(hastag){
            hastag =  this.removeSpace(hastag)
            let lastWord = this.editSocialTypeChild.split(' ').pop()
            this.editSocialTypeChild = this.editSocialTypeChild.replace(lastWord, '') 
            var lastelemnt = lastWord.startsWith("@", 0)
            var lastelemnt1 = lastWord.startsWith("#", 0)
            if(lastelemnt){
                this.editSocialTypeChild = this.editSocialTypeChild + '@'+hastag
            } else if(lastelemnt1){
                this.editSocialTypeChild = this.editSocialTypeChild + '#'+hastag
            }
            this.store.$patch({socialTopCommentHastagName : []})
        },
        pasteTagType(hastag){
            hastag =  this.removeSpace(hastag)
            let lastWord = this.topComment1.split(' ').pop()
            this.topComment1 = this.topComment1.replace(lastWord, '') 
            var lastelemnt = lastWord.startsWith("@", 0)
            var lastelemnt1 = lastWord.startsWith("#", 0)
            if(lastelemnt){
                this.topComment1 = this.topComment1 + '@'+hastag
            } else if(lastelemnt1){
                this.topComment1 = this.topComment1 + '#'+hastag
            }
            this.store.$patch({socialTopCommentHastagName : []})
        },
        pasteTagGeneral(hastag){
            hastag =  this.removeSpace(hastag)
            let lastWord = this.topComment2.split(' ').pop()
            this.topComment2 = this.topComment2.replace(lastWord, '') 
            var lastelemnt = lastWord.startsWith("@", 0)
            var lastelemnt1 = lastWord.startsWith("#", 0)
            if(lastelemnt){
                this.topComment2 = this.topComment2 + '@'+hastag
            } else if(lastelemnt1){
                this.topComment2 = this.topComment2 + '#'+hastag
            }
            this.store.$patch({socialTopCommentHastagName : []})
        },
        pasteTagGeneralEdit(hastag){
            hastag =  this.removeSpace(hastag)
            let lastWord = this.editSocial.split(' ').pop()
            this.editSocial = this.editSocial.replace(lastWord, '') 
            var lastelemnt = lastWord.startsWith("@", 0)
            var lastelemnt1 = lastWord.startsWith("#", 0)
            if(lastelemnt){
                this.editSocial = this.editSocial + '@'+hastag
            } else if(lastelemnt1){
                this.editSocial = this.editSocial + '#'+hastag
            }
            this.store.$patch({socialTopCommentHastagName : []})
        },
        pasteTagGeneralEditComment(hastag){
            hastag =  this.removeSpace(hastag)
            let lastWord = this.editCommentSocial.split(' ').pop()
            this.editCommentSocial = this.editCommentSocial.replace(lastWord, '') 
            var lastelemnt = lastWord.startsWith("@", 0)
            var lastelemnt1 = lastWord.startsWith("#", 0)
            if(lastelemnt){
                this.editCommentSocial = this.editCommentSocial + '@'+hastag
            } else if(lastelemnt1){
                this.editCommentSocial = this.editCommentSocial + '#'+hastag
            }
            this.store.$patch({socialTopCommentHastagName : []})
        },
        uploadMedia(e){
            if(e.target.files[0].size > 12000000){
                if(e.target.files[0].type.includes('image')){
                    this.mediaError = this.$t('leader_profile.imageValid')
                } else if(e.target.files[0].type.includes('video')){ 
                    this.mediaError = this.$t('leader_profile.videoValid')
                }
            } else {
                this.mediaError = ''
                if(this.editSocialID){
                    this.commentImageEdit = e.target.files[0]
                    this.commentImageSRCEdit = URL.createObjectURL(e.target.files[0])
                } else {
                    this.commentImage = e.target.files[0]
                    this.commentImageSRC = URL.createObjectURL(e.target.files[0])
                }
            }
        },
        returnProviderID(){
            if(Object.keys(this.store.userSelectedAccount).length){
                return this.store.userSelectedAccount
            } else {
                let list = this.store.userTradingAccountsList.filter(i=>(i.demo === false && i.isLeader && i.providerStatus=='APPROVED'))
                return list[0]
            }
        },
    },
    mounted() {
        if(this.$route.name == 'dashboard_portfolio'){
            this.store.callSocialList({},true, this.returnProviderID().providerId)
            this.store.callSocialTypeList({}, true, this.returnProviderID().zuluAccountId);
        }
        // let _that = this 
        /*
        $(window).scroll(()=> {
            if(($(window).scrollTop() + $(window).height() > $(document).height()- 200) && !_that.loadMoreLoading) {
                _that.loadMore()
            }
        });
        */
    },
    updated() {
        var _that = this
        $(".hashlink").click(function(e){
            if(!_that.loadMorehastag){
                _that.loadMorehastag = true
                let id = e.target.innerHTML
                id = id.replace('#','')
                _that.selectedHastag = id
                if(_that.$route.name=='socialFeed' || _that.$route.name=='myPosts'){
                    if(_that.feedType=='1'){
                        _that.store.callSocialHastagList({},true,id).then(()=>{
                            _that.loadMorehastag = false
                        })
                    } else if(_that.feedType=='3'){
                        _that.store.callSocialHastagList({},true,id,'noAuth').then(()=>{
                            _that.loadMorehastag = false
                        })
                    } else {
                        _that.store.callSocialHastagMyFeedList({},true,id).then(()=>{
                            _that.loadMorehastag = false
                        })
                    }
                } else {
                    _that.store.callSocialHastagList({},true,id).then(()=>{
                        _that.loadMorehastag = false
                    })
                }
            }
        });

        $(".traderlink").click(function(e){
            if(e.target.id != 'undefined'){
                let weblink = "/trader/" + e.target.id + "/trading?t=10000&m=1";
                _that.$router.push(weblink)
                return false;
            }
        });
    },
}
</script>